import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { cn } from '../../utils';

const Button = forwardRef(
  ({ className, variant = 'default', size = 'default', ...props }, ref) => {
    const baseStyles =
      'inline-flex items-center justify-center whitespace-nowrap rounded-full ring-offset-white transition-all duration-300 ease-in-out shadow-none hover:shadow-lg focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-neutral-900 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50';

    const variants = {
      default: 'bg-primary text-neutral',
      destructive: 'bg-red-500 text-neutral',
      outline: 'border-2 border-primary bg-white text-neutral-9',
      secondary: 'bg-neutral-100 text-neutral-9',
      ghost: 'text-neutral-9',
      link: 'text-neutral-9 underline-offset-4',
    };

    const sizes = {
      default: 'h-12 px-6 py-3',
      sm: 'h-9 rounded-md px-3',
      lg: 'h-11 rounded-md px-8',
      icon: 'h-10 w-10',
    };

    return (
      <button
        className={cn(baseStyles, variants[variant], sizes[size], className)}
        ref={ref}
        {...props}
      />
    );
  },
);

Button.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf([
    'default',
    'destructive',
    'outline',
    'secondary',
    'ghost',
    'link',
  ]),
  size: PropTypes.oneOf(['default', 'sm', 'lg', 'icon']),
  children: PropTypes.node,
};

Button.defaultProps = {
  variant: 'default',
  size: 'default',
};

Button.displayName = 'Button';

export { Button };
